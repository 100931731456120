import React, { useState, useEffect } from 'react';
import {useHistory} from 'react-router-dom';
import './Dashboard.sass';
import axios from 'axios';
import DashboardJsx from './Dashboard.jsx';


function Dashboard() {
  const [supportModalOpen, setSupportModalOpen] = useState(false);
  const [totalReports, setTotalReports] = useState(0);
  const history = useHistory();

  useEffect(() => {
    checkAuth()
  }, [])

  const checkAuth = () => {
    let key = localStorage.getItem('key');
    let timestamp = localStorage.getItem('timestamp');
    if (!key || !timestamp) {
      localStorage.clear();
      history.push(`/login`);
    }
    let currentTime = Date.now();
    let timeLimit = parseInt(timestamp) + 12 * 60 * 60 * 1000;
      if (timeLimit <= currentTime) {
        localStorage.clear();
        history.push(`/login`);
      }

    let config = {
      headers:{
      "Authorization": `Bearer ${key}`
      }
    }

    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/reports`, config)
      .then(res => {
        setTotalReports(res.data.reports_total)
      }).catch(err => {
      })

    }

  const handleOpen = () => {
    setSupportModalOpen(true);
  }

  const handleClose = () => {
    setSupportModalOpen(false);
  }

  return(
      <DashboardJsx
        handleClose={handleClose}
        handleOpen={handleOpen}
        totalReports={totalReports}
        open={supportModalOpen} />
  )
}

export default Dashboard;
