import React, {useState} from 'react';
import './UserApprove.sass';
import UserApproveJsx from './UserApprove.jsx';
import axios from 'axios'

function UserApprove(props) {

  const [isManufacturerAdmin, setIsManufacturerAdmin] = useState(false)
  const [manufacturers, setManufacturers] = useState([])
  const [isError, setIsError] = useState([])

  const handleChange = (e, index) => {

    let manufacturerArr = manufacturers;
    manufacturerArr[index] = e.target.value;
    setManufacturers(manufacturerArr)

  }

  const handleSubmit = (e) => {
    e.preventDefault()
    let userRole = isManufacturerAdmin ? 'vendor_admin' : 'vendor_user'
    let data = {
      action: 'approve',
      manufacturers: manufacturers,
      role: userRole
    }

    let key = localStorage.getItem('key')
    let config = {
      headers:{
      "Authorization": `Bearer ${key}`
      }
    }
    console.log(data)
    axios.post(process.env.REACT_APP_API_ENDPOINT + '/users/' + props.userID + '/action', data, config)
      .then(res => {
        console.log(res)
        props.handleApproveClose();
      }).catch(err => {
        console.log(err.response)
        debugger;
        setIsError(err.response.data.error_message);
      })
  }

  const removeManufacturer = (i) => {
    let a = [...manufacturers]
    console.log(`i : ${i}`)
    console.log(`a : ${a} first time`)
    a.splice(i, 1)
    console.log(`a : ${a} second time`)
    setManufacturers(a)
  }

  const addManufacturer = () => {
    setManufacturers(v => [...v, -1])
  }

  const toggleManufacturerAdmin = () => {
    setIsManufacturerAdmin(!isManufacturerAdmin)
  }

  return(
      <UserApproveJsx
      userID={props.userID}
      removeManufacturer={removeManufacturer}
      addManufacturer={addManufacturer}
      manufacturers={manufacturers}
      manufacturerList={props.manufacturerList}
      isManufacturerAdmin={isManufacturerAdmin}
      toggleManufacturerAdmin={toggleManufacturerAdmin}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      isError={isError}/>
  )
}

export default UserApprove;
