import React, {useState} from 'react';
import './UserReject.sass';
import UserRejectJsx from './UserReject.jsx';
import axios from 'axios'



function UserReject(props) {

  const [reason, setReason] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const setFormValues = (e) => {
    setReason(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    let data = {
      action: 'decline',
      decline_reason: reason
    }

    console.log(data)

    let key = localStorage.getItem('key')
    let config = {
      headers:{
      "Authorization": `Bearer ${key}`
      }
    }

    setIsLoading(true)
    axios.post(process.env.REACT_APP_API_ENDPOINT + '/users/' + props.userID + '/action', data, config)
      .then(res => {
        console.log(res)
        setIsLoading(false)
        setIsSuccess(true)
      }).catch(err => {
        console.log(err.response)
      })
  }

  return(
      <UserRejectJsx
      setFormValues={setFormValues}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      isSuccess={isSuccess}/>
  )
}

export default UserReject;
