import React from "react";
import Filters from "./filters/Filters";
import Report from "./report/Report";
import { Box, Typography, Grid, Checkbox, Divider } from "@material-ui/core";

import Paginator from "../../../components/paginator/Paginator";

export const ReportsJsx = (props) => {
  return (
    <div className="main-container">
      <Box mx={3.157} mt={3.157} className="reports_wrapper">
        <Typography variant="h4" className="reports-title">
          Reports
        </Typography>
        <Box mt={3.157} mb={2.369}>
          <Filters
            reportTypes={props.reportTypes}
            triggerFilterQuery={props.triggerFilterQuery}
            multipleStatement={props.multipleStatement}
            downloadSelected={props.downloadSelected}
            downloadErr={props.downloadErr}
          />
        </Box>
        <Grid container>
          <Grid item xs={1}>
            <Checkbox
              onChange={() => props.setAllChecked()}
              value={1}
              checked={props.isAllChecked}
            />
          </Grid>
          <Grid item xs={3}>
            <Box mt={1.16}>
              <Typography variant="subtitle2">Report Name</Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box mt={1.16}>
              <Typography variant="subtitle2">Report Type</Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Box mt={1.16}>
              <Typography variant="subtitle2">Date Added</Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box mt={1.16}>
              <Typography variant="subtitle2" align="center">
                Actions
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Divider />
        {props.reports.length === 0 && (
          <h2 className="no-results">No reports found</h2>
        )}
        {props.reports.length > 0 &&
          props.reports.map((report, i) => {
            return (
              <Report
                key={i}
                report={report}
                setReportChecked={props.setReportChecked}
                reportIndex={i}
              />
            );
          })}
        {!props.isLoading && props.reports.length > 0 && (
          <Paginator
            total={props.total}
            currentPage={props.page}
            limit={props.limit}
            setActivePage={props.setActivePage}
          />
        )}
      </Box>
    </div>
  );
};

export default ReportsJsx;
