import React from 'react';
import {Typography, Grid, Box, Button, Checkbox} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

export const UserSingleJsx = (props) => {

return (
<div className='user-single'>
  <Grid container className="table-row">
    <Grid item xs={1}>
      <Checkbox onChange={() => props.setUserChecked(props.userIndex)} checked={props.user.isChecked}/>
    </Grid>
    <Grid item xs={2}>
      <Box mt={1.16}>
      <Typography variant="subtitle2">
      {props.user.name}
      </Typography>
      </Box>
    </Grid>
    <Grid item xs={2}>
      <Box mt={1.16}>
      <Typography variant="subtitle2">
      {props.user.role_string}
      </Typography>
      </Box>
    </Grid>
    <Grid item xs={2}>
      <Box mt={1.16}>
      <Typography variant="subtitle2">
      {props.user.company_name}
      </Typography>
      </Box>
    </Grid>
    <Grid item xs={4}>
      <Box mt={1.16}>
      <Typography variant="subtitle2">
      {props.showAllChecked && props.user.user_manufacturers?.map(function(manufacturer, index) {
        let commaString = "";
        if (index > 0) {
          commaString =  ", ";
        }
        return (<span>{commaString}{manufacturer.name}</span>
          )
      })}
      {!props.showAllChecked && props.user.user_manufacturers?.map(function(manufacturer, index) {
        let commaString = "";
        if (index > 0) {
          commaString =  ", ";
        }
        if (index >= 2) {
          return ""
        }
        return (<span>{commaString}{manufacturer.name}</span>
          )
      })}
        {!props.showAllChecked && props.user.user_manufacturers?.length >= 3 &&
          <span class="manufacturer-all-link" onClick={(e) => props.handleViewAllClick(e)}>See All</span>
        }
      </Typography>
      </Box>
    </Grid>
    <Grid item xs={1}>
    <Box mt={1.16}>
    <Grid container>
      <Grid item xs={12} className="table-btn-container">
        <Button size="small" startIcon={<EditIcon />} className="btn-theme" href={`/dashboard/user-management/edit-user/${props.user.id}`}>Edit</Button>
      </Grid>
    </Grid>
    </Box>
    </Grid>
  </Grid>
</div>
)
}

export default UserSingleJsx;
