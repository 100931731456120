import React from 'react';
import {Typography, InputLabel, Box, Container, Select, MenuItem, TextField, Button, CircularProgress} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';


export const SupportJsx = (props) => {
return (
<div className='support'>
<Box ml={3} mr={3} display="flex" justifyContent="center" flexDirection="column">
  <Box m={2.369} mb={1.333}>
    <Typography align="center" variant="h5" className='blue-font'>
    <strong>Support</strong>
    </Typography>
  </Box>
  <Box ml={2.369} mr={2.369} mt={1}>
    <Typography align="center" variant="subtitle2" className="support-text">
    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    Aliquam et tellus ut nibh rutrum volutpat.
    Morbi porttitor vestibulum ex, vel.
    </Typography>
  </Box>
  <Box m={2.369}>
    <Typography align="center" variant="subtitle2" className="support-text label_fld">
    <strong>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</strong>
    </Typography>
  </Box>
  {!props.success &&
  <form onSubmit={(e) => props.handleSubmit(e)}>
  <Container>
    <Select
      labelId="question-select"
      variant="outlined"
      className='select-input'
      placeholder={'What is your question?'}
      onChange={(e) => props.handleChange(e)}
      defaultValue='none'
    >
      <MenuItem value={'none'}>Please select</MenuItem>
      {props.questions.map((q, i) => {
        return <MenuItem key={i} value={q.id}>{q.question}</MenuItem>
      })}
    </Select>
  </Container>
  <Container>
  <Box mb={1} mt={1}>
    <TextField className='full-width' id="supportMessage" size='small' multiline rows={7} label="Message" variant="outlined" onBlur={(e) => props.setFormValues(e)} />
    <InputLabel className='error-label'>{props.messageErr}</InputLabel>
  </Box>
  <InputLabel className='error-label'>{props.formErr}</InputLabel>
  <Box mb={2} mt={2} display="flex" justifyContent="center">
  {
  !props.isLoading && <Button className='btn-theme' variant="contained" color="primary" type="submit">
    Send
  </Button>
  }
  {
  props.isLoading && <CircularProgress className="centered" />
  }
  </Box>
  </Container>
  </form>
  }
  {props.success &&
  <React.Fragment>
  <Box display={'flex'} justifyContent={'center'} mt={4.109}>
  <Typography align="center" variant="subtitle2" className="support-text">
  <strong>Thank you for your question!</strong>
  </Typography>
  </Box>
  <Box display={'flex'} justifyContent={'center'} mt={1.777}>
  <CheckCircleIcon className='blue-font big-icon'/>
  </Box>
  </React.Fragment>
  }
</Box>
</div>
)
}

export default SupportJsx;
