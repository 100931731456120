import React, {useEffect} from 'react';
import {Typography, Grid, Box, Button, Checkbox, TextField, Select, MenuItem, CircularProgress, InputLabel} from '@material-ui/core';
import UserSingle from './user-single/UserSingle'
import UserRequest from './user-single/UserRequest'
import Paginator from '../../../components/paginator/Paginator'

export const UserManagementJsx = (props) => {

useEffect(() => {
    console.log('UserManagementJsx re-rendered')
}, [props.requests])

return (
<div className='main-container user-management'>
<Box mx={3.157}>
  <Box mt={3.157} mb={3.157}>
  <Typography variant="h4" className="support-title">
  User Management
  </Typography>
  </Box>
  {props.requests.length >= 1 && 
    <React.Fragment>
  <Grid container>
    <Grid item xs={6}>
    <Box display={"flex"}>
      <Box mr={1}>
      <Select
        variant='outlined'
        labelId="question-select"
        className='select-input'
        onChange={(e) => props.handleAction(e)}
        defaultValue='none'
      >
        <MenuItem value={'none'}>Please select</MenuItem>
        <MenuItem value={'approve'}>Approve</MenuItem>
        <MenuItem value={'reject'}>Reject</MenuItem>
      </Select>
      </Box>
      {props.bulkMode === 'approve' && <Box mr={1}>
      <Select
        variant='outlined'
        labelId="role-select"
        className='select-input'
        onChange={(e) => props.handleRole(e)}
        defaultValue='none'
      >
        <MenuItem value={'none'}>Please select</MenuItem>
        <MenuItem value={'vendor_user'}>User</MenuItem>
        <MenuItem value={'vendor_admin'}>Admin</MenuItem>
      </Select>
      </Box>}
      {props.bulkMode === 'reject' && <Box mr={1}>
      <Select
        variant='outlined'
        labelId="reason-select"
        className='select-input'
        onChange={(e) => props.handleReason(e)}
        defaultValue='none'
      >
        <MenuItem value={'none'}>Please select</MenuItem>
        <MenuItem value={'reason1'}>Reason</MenuItem>
        <MenuItem value={'reason2'}>Lorem Ipsum dolor</MenuItem>
        <MenuItem value={'reason3'}>Lorem Ipsum</MenuItem>
      </Select>
      </Box>}
      <Box mx={3.157} ml={0}>
      {!props.isBulkRequestLoading && <Button className='btn-theme' onClick={() => props.applyBulkActions()}>Apply</Button>}
      {props.isBulkRequestLoading && <CircularProgress />}
      </Box>
      {<InputLabel className='error-label form-link'>{props.isBulkPendingError}</InputLabel>}
    </Box>
    </Grid>
  </Grid>
  <Grid container className="table-header">
    <Grid item xs={1}>
      <Checkbox onChange={() => props.setAllChecked()}/>
    </Grid>
    <Grid item xs={3}>
      <Box mt={1.16}>
      <Typography variant="subtitle2">
      Full Name
      </Typography>
      </Box>
    </Grid>
    <Grid item xs={3}>
      <Box mt={1.16}>
      <Typography variant="subtitle2">
      Company Name
      </Typography>
      </Box>
    </Grid>
    <Grid item xs={3}>
      <Box mt={1.16}>
      <Typography variant="subtitle2">
      Email Address
      </Typography>
      </Box>
    </Grid>
    <Grid item xs={2}>
    <Box mt={1.16}>
    <Typography variant="subtitle2">
    Actions
    </Typography>
    </Box>
    </Grid>
  </Grid>
  {props.requests.map((request, i) => {
    return <UserRequest
    key={i}
    requestIndex={i}
    manufacturerList={props.manufacturerList}
    handleApproveRefresh={props.handleApproveRefresh}
    request={request}
    setChecked={props.setChecked}/>
  })}
  </React.Fragment>
  }
  <br/>
  <Grid container>
    <Grid item xs={6}>
      <Box display={"flex"}>
        <Box mr={1}>
        <Select
          onChange={(e) => props.handleUserAction(e)}
          variant='outlined'
          labelId="user-action-select"
          className='select-input'
          defaultValue='none'
        >
          <MenuItem value={'none'}>Please select</MenuItem>
          <MenuItem value={'manufacturer'}>Assign Manufacturer</MenuItem>
        </Select>
        </Box>
        {props.bulkUser === 'manufacturer' &&
        <Box mr={1}>
        <Select
          variant='outlined'
          labelId="reason-select"
          className='select-input'
          onChange={(e) => props.handleManufacturer(e)}
          defaultValue='none'
        >
          <MenuItem value={'none'}>Please select</MenuItem>
          { props.manufacturerList.map((v, j) => {
            return <MenuItem key={j} value={v.id}>{v.name}</MenuItem>
          })
          }
        </Select>
        </Box>}
        <Box mx={3.157} ml={0}>
        {!props.isBulkUserLoading && <Button className='btn-theme' onClick={props.applyBulkManufacturer}>Apply</Button>}
        {props.isBulkUserLoading && <CircularProgress />}
        </Box>
        <Box mx={3.157} ml={0}>
        {<InputLabel className='error-label form-link'>{props.isBulkError}</InputLabel>}
        {<InputLabel className='success-label form-link'>{props.isBulkSuccess}</InputLabel>}
        </Box>
      </Box>
    </Grid>
    <Grid item xs={6}>
      <TextField variant="outlined" size="small" className='full-width' placeholder="Search for a user..." onKeyUp={(e) => props.searchUser(e)} id="userSearch"></TextField>
    </Grid>
  </Grid>
  <br/>
  <Grid container className="table-header">
    <Grid item xs={1}>
      <Checkbox onChange={() => props.setAllUsersChecked()}/>
    </Grid>
    <Grid item xs={2}>
      <Box mt={1.16}>
      <Typography variant="subtitle2">
      Full Name
      </Typography>
      </Box>
    </Grid>
    <Grid item xs={2}>
      <Box mt={1.16}>
      <Typography variant="subtitle2">
      User Role
      </Typography>
      </Box>
    </Grid>
    <Grid item xs={2}>
      <Box mt={1.16}>
      <Typography variant="subtitle2">
      Company Name
      </Typography>
      </Box>
    </Grid>
    <Grid item xs={4}>
    <Box mt={1.16}>
    <Typography variant="subtitle2">
    Manufacturers
    </Typography>
    </Box>
    </Grid>
    <Grid item xs={1}>
    <Box mt={1.16}>
    <Typography variant="subtitle2">
    Actions
    </Typography>
    </Box>
    </Grid>
  </Grid>
  {!props.isLoading && props.users.map((user, i) => {
    return <UserSingle
    key={i}
    user={user}
    userIndex={i}
    setUserChecked={props.setUserChecked} />
  })}
  {!props.isLoading && 
  <Paginator
    total={props.total}
    currentPage={props.page}
    limit={props.limit}
    setActivePage={props.setActivePage}
     />
  }
</Box>
<div className="network-graphic"></div>
</div>
)
}

export default UserManagementJsx;
