import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import './UserEdit.sass';
import UserEditJsx from './UserEdit.jsx';
import axios from 'axios';

function UserEdit(props) {
  const [user, setUser] = useState(null);
  const [manufacturers, setManufacturers] = useState([])
  const [userManufacturers, setUserManufacturers] = useState([])

  const [name, setName] = useState('')
  const [nameTouched, setNameTouched] = useState(false)
  const [nameErr, setNameErr] = useState('')

  const [email, setEmail] = useState('')
  const [emailTouched, setEmailTouched] = useState(false)
  const [emailErr, setEmailErr] = useState('')

  const [company, setCompany] = useState('')
  const [companyTouched, setCompanyTouched] = useState(false)
  const [companyErr, setCompanyErr] = useState('')

  const [website, setWebsite] = useState('')
  const [websiteTouched, setWebsiteTouched] = useState(false)
  const [websiteErr, setWebsiteErr] = useState('')

  const [role, setRole] = useState('')
  const [roleTouched, setRoleTouched] = useState(false)
  const [roleErr, setRoleErr] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const history = useHistory();

  useEffect(() => {
    if(!user) {
        getUser();
    }
  }, [])

  const getUser = () => {
    let key = localStorage.getItem('key');
    let timestamp = localStorage.getItem('timestamp');
    if (!key || !timestamp) {
      localStorage.clear();
      history.push(`/login`);
    }
    let currentTime = Date.now();
    let timeLimit = parseInt(timestamp) + 12 * 60 * 60 * 1000;
      if (timeLimit <= currentTime) {
        localStorage.clear();
        history.push(`/login`);
      }

    let config = {
      headers:{
      "Authorization": `Bearer ${key}`
      }
    }
    const path = props.location.pathname
    const id = path.substring(path.length, path.lastIndexOf('/'))
    axios.get(process.env.REACT_APP_API_ENDPOINT + '/users' + id, config)
      .then(res => {
        console.log(res);
        setUser(res.data.user)
        setName(res.data.user.name)
        setEmail(res.data.user.email)
        // setCompany(res.data.user.company)
        // setWebsite(res.data.user.website)
        setRole(res.data.user.role)
        setManufacturers(res.data.manufacturers)
        setUserManufacturers(res.data.user.user_manufacturers)
      })
      .catch(err => {
        console.log(err.response.status)
        if (err.response.data && err.response.data.error_message == "You cannot edit a pending user") {
          history.push(`/dashboard/user-management`);
        } else {
          if(err.response.status) {
            history.push(`/dashboard`);
          }
        }
      })
  }

  const setFormValues = (e) => {
    if(e.target.id === 'userName') {
      setName(e.target.value);
      setNameTouched(true);
    }
    if(e.target.id === 'userEmail'){
      setEmail(e.target.value)
      setEmailTouched(true)
    }
    if(e.target.id === 'userCompany'){
      setCompany(e.target.value)
      setCompanyTouched(true)
    }
    if(e.target.id === 'userWebsite'){
      setWebsite(e.target.value)
      setWebsiteTouched(true)
    }
    if(e.target.id === 'userRole'){
      setRole(e.target.value)
      setRoleTouched(true)
    }
  }

  const setDropdownValues = (e, data) => {
      setRole(e.target.value)
      if (e.target.value == 'vendor_admin') {
        let a = []
        manufacturers.forEach((v, j) => {
          a.push(v.id);
        });
        setUserManufacturers(a);
      }
      setRoleTouched(true)
  }

  useEffect(() => {
    validateForm();
  }, [setFormValues])

  const validateForm = () => {
    setNameErr('')
    setCompanyErr('')
    setEmailErr('')
    setWebsiteErr('')

    if(!name && nameTouched) {
      setNameErr('Name is required')
    }
    if(!company && companyTouched) {
      setCompanyErr('Company is required')
    }
    if(!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) && emailTouched) {
      setEmailErr('Please enter a valid email')
    } else {

    }
    if(!website.match(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/) && emailTouched) {
      setWebsiteErr('Please enter a valid website URL')
    }
  }


  const handleSubmit = (e) => {
    e.preventDefault()

    let manufacturerIDs = userManufacturers.filter(v => v !== '')
    setErrorMessage('');

    let data = {
      name: name,
      email: email,
      company: company,
      website: website,
      role: role,
      manufacturers: manufacturerIDs
    }
    console.log(data);
    let key = localStorage.getItem('key')
    let config = {
      headers:{
      "Authorization": `Bearer ${key}`
      }
    }

    const path = props.location.pathname
    const id = path.substring(path.length, path.lastIndexOf('/'))
    setIsLoading(true)
    axios.post(process.env.REACT_APP_API_ENDPOINT + '/users' + id, data, config)
      .then(res => {
        setIsLoading(false)
        if(res.status == 200) {setIsSuccess(true)}
        console.log(res);
        getUser();
      }).catch(err => {
        console.log(err.response.status)
        if (err.response.data && err.response.data.errors) {
          if (err.response.data.errors['role']) {
            setIsLoading(false)
            setIsSuccess(false)
            setErrorMessage(err.response.data.errors['role'])
            return
          }
          if (err.response.data.errors['__all__']) {
            setIsLoading(false)
            setIsSuccess(false)
            setErrorMessage(err.response.data.errors['__all__']);
            return
          }

        }
      })
  }

  const removeManufacturer = (i) => {
    let a = []
    userManufacturers.forEach((v, j) => {
      if ( j !== i ) {
        a.push(v)
      }
    });
    setUserManufacturers(a)
  }

  const addManufacturer = () => {
    let a = []
    userManufacturers.forEach((v, j) => {
      a.push(v)
    });
    a.push("");
    setUserManufacturers(a);
  }

  const updateManufacturer = (e, index) => {
    let a = []
    userManufacturers.forEach((v, j) => {
      if (j == index) {
        a.push(e.target.value);
      } else {
        a.push(v);
      }
    });
    setUserManufacturers(a);
  }

  return(
      <UserEditJsx
        isLoading={isLoading}
        isSuccess={isSuccess}
        user={user}
        role={role}
        userManufacturers={userManufacturers}
        manufacturers={manufacturers}
        handleSubmit={handleSubmit}
        setFormValues={setFormValues}
        setDropdownValues={setDropdownValues}
        removeManufacturer={removeManufacturer}
        addManufacturer={addManufacturer}
        updateManufacturer={updateManufacturer}
        errorMessage={errorMessage}
        />
  )
}

export default UserEdit;
