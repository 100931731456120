import React from "react";
import {
  Typography,
  Grid,
  Box,
  Button,
  Modal,
  InputLabel,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import Support from "../support/Support";

export const ProfileJsx = (props) => {
  return (
    <div className="profile">
      <Box mx={4.209}>
        <Box mt={2.369}>
          <Typography variant="h4" className="support-title">
            Profile
          </Typography>
        </Box>
        <Box my={2.369} className="copy_block">
          <p>
            If you would like to edit your profile please get in touch with us
            by clicking on the button below
          </p>
        </Box>
        <Grid container>
          <Grid item xs={6}>
            <Box my={1.777}>
              <div className="profile-info-group">
                <Typography variant="subtitle1" className="bold blue-font">
                  Full Name
                </Typography>
                <Typography variant={"h6"}>{props.name}</Typography>
              </div>
            </Box>
            <Box my={1.777}>
              <div className="profile-info-group">
                <Typography variant="subtitle1" className="bold blue-font">
                  Email
                </Typography>
                <Typography variant={"h6"}>{props.email}</Typography>
              </div>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <div className="profile-info-row">
              <Box my={1.777}>
                <div className="profile-info-group">
                  <Typography variant="subtitle1" className="bold blue-font">
                    Company
                  </Typography>
                  <Typography variant={"h6"}>{props.company}</Typography>
                </div>
              </Box>
            </div>
          </Grid>
        </Grid>

        <Box mt={1.777} mb={3.157}>
          <Button
            variant="contained"
            className="btn-theme"
            onClick={() => props.handleOpen()}
          >
            Get in Touch
          </Button>
        </Box>
        <form onSubmit={(e) => props.handleSubmit(e)}>
          <Grid item xs={12}>
            <Box mt={3.157} mb={1}>
              <Typography variant={"subtitle1"}>Change Password</Typography>
            </Box>
            <Box my={1}>
              <Typography variant={"subtitle2"}>
                If you would like to change your password, please fill out the
                form below
              </Typography>
            </Box>
            <Box m={0} className="form-group">
              <InputLabel className="form-label">
                Enter your new password
              </InputLabel>
              <TextField
                variant="outlined"
                size="small"
                id="resetPassword"
                type="password"
                className="full-width"
                onKeyUp={(e) => props.setFormValues(e)}
              />
              {
                <InputLabel className="error-label form-link">
                  {props.passwordErr}
                </InputLabel>
              }
            </Box>
            <Box m={0} className="form-group">
              <InputLabel className="form-label">
                Confirm your new password
              </InputLabel>
              <TextField
                disabled={props.confirmSuccess}
                variant="outlined"
                size="small"
                id="confirmPassword"
                type="password"
                className="full-width"
                onKeyUp={(e) => props.setFormValues(e)}
              />
              {
                <InputLabel className="error-label form-link">
                  {props.confirmErr}
                </InputLabel>
              }
              {
                <InputLabel className="form-link">
                  {props.confirmSuccess}
                </InputLabel>
              }
            </Box>
            <Box m={0} display="flex" flexDirection="center">
              {!props.isLoading && !props.confirmSuccess && (
                <Button
                  variant="contained"
                  align="center"
                  className="btn-theme"
                  type="submit"
                >
                  Reset Password
                </Button>
              )}
              {props.isLoading && <CircularProgress className="centered" />}
            </Box>
          </Grid>
        </form>
      </Box>
      <div className="network-graphic"></div>
      <Modal open={props.open} onClose={() => props.handleClose()}>
        <Support />
      </Modal>
    </div>
  );
};

export default ProfileJsx;
