import React, { useState, useEffect } from "react";
import "./Range.sass";
import RangeJsx from "./Range.jsx";
import { startOfWeek, lastDayOfWeek, subWeeks, format } from "date-fns";

function Range(props) {
  var filter = {};
  const now = new Date();
  const currentYear = now.getFullYear();
  let yearList = [];
  for(let i = currentYear; i >= 2015; i--) {
    yearList.push(i);
  }
  const [year, setYear] = useState(currentYear);
  const [month, setMonth] = useState(null);

  useEffect(() => {
    generateQuery();
  }, [year, month]); // eslint-disable-line react-hooks/exhaustive-deps

  const generateQuery = () => {
    if (month) {
      if (!year) {
        var d = new Date();
        let year = d.getFullYear();
      }
      let day = "";
      if (
        month === "04" ||
        month === "06" ||
        month === "09" ||
        month === "11"
      ) {
        day = "30";
      }
      if (
        month === "01" ||
        month === "03" ||
        month === "05" ||
        month === "07" ||
        month === "08" ||
        month === "10" ||
        month === "12"
      ) {
        day = "31";
      }
      if (month === "02") {
        day = "28";
      }
      filter["from"] = "01/" + month + "/" + year;
      filter["to"] = day + "/" + month + "/" + year;
      props.triggerRangeQuery(filter);
      return;
    }
    if (year) {
      filter["from"] = "01/01/" + year;
      filter["to"] = "31/12/" + year;
      props.triggerRangeQuery(filter);
      return;
    }
    filter["from"] = null;
    filter["to"] = null;
    props.triggerRangeQuery(filter);
    return;
  };

  const triggerYearQuery = (e) => {
    setYear(
      !e.target.value || (e.target.value && e.target.value === "none")
        ? currentYear
        : e.target.value
    );
  };

  const triggerMonthQuery = (e) => {
    setMonth(
      !e.target.value || (e.target.value && e.target.value === "none")
        ? null
        : e.target.value
    );
  };

  const triggerWeekQuery = (e) => {
    let currentDate = new Date();

    if (e.target.value === "this") {
      filter["from"] = format(
        startOfWeek(currentDate, { weekStartsOn: 1 }),
        "dd/MM/yyyy"
      );
      filter["to"] = format(
        lastDayOfWeek(currentDate, { weekStartsOn: 1 }),
        "dd/MM/yyyy"
      );
    }

    if (e.target.value === "last") {
      filter["from"] = format(
        startOfWeek(subWeeks(currentDate, 1), { weekStartsOn: 1 }),
        "dd/MM/yyyy"
      );
      filter["to"] = format(
        lastDayOfWeek(subWeeks(currentDate, 1), { weekStartsOn: 1 }),
        "dd/MM/yyyy"
      );
    }

    if (e.target.value === "twoWeeks") {
      filter["from"] = format(
        startOfWeek(subWeeks(currentDate, 2), { weekStartsOn: 1 }),
        "dd/MM/yyyy"
      );
      filter["to"] = format(
        lastDayOfWeek(subWeeks(currentDate, 2), { weekStartsOn: 1 }),
        "dd/MM/yyyy"
      );
    }
    props.triggerRangeQuery(filter);
  };

  return (
    <div>
      <RangeJsx
        triggerRangeQuery={props.triggerRangeQuery}
        triggerTypeQuery={props.triggerTypeQuery}
        triggerYearQuery={triggerYearQuery}
        triggerMonthQuery={triggerMonthQuery}
        triggerWeekQuery={triggerWeekQuery}
        disableMonth={!year}
        yearList={yearList}
        currentYear={currentYear}
        reportTypes={props.reportTypes}
      />
    </div>
  );
}

export default Range;
