import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import './Support.sass';
import SupportJsx from './Support.jsx';
import axios from 'axios';

function Support() {

  const [questions, setQuestions] = useState([])
  const [question, setQuestion] = useState('');
  const [message, setMessage] = useState('')
  const [questionErr, setQuestionErr] = useState('');
  const [messageErr, setMessageErr] = useState('')
  const [formErr, setFormErr] = useState('')
  const [questionTouched, setQuestionTouched] = useState('');
  const [messageTouched, setMessageTouched] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const history = useHistory();

  useEffect(() => {
    if(questions.length === 0) {
      getQuestions();
    }
  }, [])// eslint-disable-line react-hooks/exhaustive-deps

  const getQuestions = () => {
    let key = localStorage.getItem('key');
    let timestamp = localStorage.getItem('timestamp');
    if (!key || !timestamp) {
      localStorage.clear();
      history.push(`/login`);
    }
    let currentTime = Date.now();
    let timeLimit = parseInt(timestamp) + 12 * 60 * 60 * 1000;
      if (timeLimit <= currentTime) {
        localStorage.clear();
        history.push(`/login`);
      }

    let config = {
      headers:{
      "Authorization": `Bearer ${key}`
      }
    }
    axios.get(process.env.REACT_APP_API_ENDPOINT + '/support', config)
      .then(res => {
        console.log(res.data)
        setQuestions(res.data.questions_list)
      })
  }


  const setFormValues = (e) => {
    if(e.target.id === 'supportMessage'){
      setMessage(e.target.value)
      setMessageTouched(true)
    }
  }

  const handleChange = (e) => {
    setQuestion(!e.target.value || (e.target.value && e.target.value === 'none') ? '' : e.target.value)
    setQuestionTouched(true)
  }

  useEffect(() => {
    validateForm();
  }, [setFormValues]);// eslint-disable-line react-hooks/exhaustive-deps

  const validateForm = () => {

    setQuestionErr('')
    setMessageErr('')

    if(!question && questionTouched) {
      setQuestionErr('Question is required')
    }
    if(!message && messageTouched) {
      setMessageErr('Message is required')
    }
    if (question && message) {
      setFormErr('');
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    let data = {
      question: question,
      message: message
    }
    if (!question || !message) {
      setFormErr("All fields are required");
      return;
    }
    setIsLoading(true);

    let key = localStorage.getItem('key')
    let config = {
      headers:{
      "Authorization": `Bearer ${key}`
      }
    }
    console.log(data)
    axios.post(process.env.REACT_APP_API_ENDPOINT + '/support/send-question', data, config)
      .then(res => {
        console.log(res);
        setIsLoading(false);
        setSuccess(true)
      }).catch(err => {
        console.log(err.response)
      })
  }

  return(
      <SupportJsx
      handleSubmit={handleSubmit}
      setFormValues={setFormValues}
      handleChange={handleChange}
      question={question}
      questionErr={questionErr}
      messageErr={messageErr}
      formErr={formErr}
      isLoading={isLoading}
      success={success}
      questions={questions}
      />
  )
}

export default Support;
