import React from "react";
import "./Filters.sass";
import FiltersJsx from "./Filters.jsx";

function Filters(props) {
  // var filter = {};
  const [filter, setFilter] = React.useState({});
 
  React.useEffect(() => {
    props.triggerFilterQuery(filter);
  }, [filter])

  const triggerSearchQuery = (searchItem) => {
    setFilter({...filter, search: searchItem})
  };

  const triggerTypeQuery = (e) => {
    const reportType =
      !e.target.value || (e.target.value && e.target.value === "none")
        ? null
        : e.target.value;
    setFilter({...filter, type: reportType})
  };

  const triggerRangeQuery = (date) => {
    setFilter({...filter, from: date.from, to: date.to})
  };

  return (
    <div>
      <FiltersJsx
        reportTypes={props.reportTypes}
        triggerSearchQuery={triggerSearchQuery}
        multipleStatement={props.multipleStatement}
        triggerTypeQuery={triggerTypeQuery}
        triggerRangeQuery={triggerRangeQuery}
        downloadSelected={props.downloadSelected}
        downloadErr={props.downloadErr}
      />
    </div>
  );
}

export default Filters;
