import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import './UserManagement.sass';
import UserManagementJsx from './UserManagement.jsx';
import axios from 'axios';

function UserManagement() {
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(0);
  const [requests, setRequests] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [isAllUsersChecked, setIsAllUsersChecked] = useState(false);
  const [bulkMode, setBulkMode] = useState('');
  const [bulkUser, setBulkUser] = useState('');
  const [bulkRole, setBulkRole] = useState('');
  const [bulkReason, setBulkReason] = useState('');
  const [bulkManufacturer, setBulkManufacturer] = useState('');
  const [isBulkRequestLoading, setIsBulkRequestLoading] = useState(false);;
  const [isBulkError, setIsBulkError] = useState(null);
  const [isBulkPendingError, setIsBulkPendingError] = useState(null);
  const [isBulkSuccess, setIsBulkSuccess] = useState(null);
  const history = useHistory();
  // for filtering
  const [limit, setLimit] = useState(10);
  const [searchRequest, setSearchRequest] = useState({
    page: 1,
    search: '',
  });
  const maxLimit = 10;
  var searchTimeout = null;

  useEffect(() => {
    getUsers()
  }, [searchRequest])  // eslint-disable-line react-hooks/exhaustive-deps

  const getQueryString = () => {
    let obj = {},
        str = [];
    if (searchRequest.search) { obj['search'] = searchRequest.search; }
    if (searchRequest.page) { obj['page'] = searchRequest.page; } else { obj['page'] = 1; }
    if (limit && !isNaN(limit) && parseInt(limit, 10) <= 100 && parseInt(limit, 10) >= 1) { obj['limit'] = limit; } else { obj['limit'] = maxLimit; }

    for (var p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    }
    return str.join("&");
  }

  const getUsers = () => {
    console.log("Getting users!");
    let key = localStorage.getItem('key');
    let timestamp = localStorage.getItem('timestamp');
    if (!key || !timestamp) {
      localStorage.clear();
      history.push(`/login`);
    }
    let currentTime = Date.now();
    let timeLimit = parseInt(timestamp) + 12 * 60 * 60 * 1000;
      if (timeLimit <= currentTime) {
        localStorage.clear();
        history.push(`/login`);
      }

    let config = {
      headers:{
      "Authorization": `Bearer ${key}`
      }
    }
    let queryString = getQueryString();
    setIsLoading(true)
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/users?` + queryString, config)
      .then(res => {
        setTotal(res.data.data.total);
        if(res.data.data.users) {
          let d = res.data.data.users;
          d.forEach((el, i) => {
            el.isChecked = false
          });
          setUsers(d)
        }
        if(res.data.data.pending_users) {
          let d = res.data.data.pending_users;
          d.forEach((el, i) => {
            el.isChecked = false
          });
          setRequests(d)
        }
        setManufacturers(res.data.data.manufacturers)
        setIsLoading(false)
      }).catch(err => {
          // if(err.response.status) {
          //   history.push(`/dashboard`);
          // }
      })
  }

  const searchUser = (e) => {
    if (searchRequest.search !== e.target.value) {
      clearTimeout(searchTimeout);
      var tempSearch = e.target.value;
      searchTimeout = setTimeout(() => { setSearchRequest({
        'page': 1,
        'search': tempSearch
      }); }, 350);
    }
  }

  const setActivePage = (page) => {
    setSearchRequest({'page': page, 'search': searchRequest.search});
  }

  const handleAction = (e) => {
    setBulkMode(!e.target.value || (e.target.value && e.target.value === 'none') ? '' : e.target.value)
  }

  const handleUserAction = (e) => {
    setBulkUser(!e.target.value || (e.target.value && e.target.value === 'none') ? '' : e.target.value)
  }

  const handleRole = (e) => {
    setBulkRole(!e.target.value || (e.target.value && e.target.value === 'none') ? '' : e.target.value)
  }

  const handleReason = (e) => {
    setBulkReason(!e.target.value || (e.target.value && e.target.value === 'none') ? '' : e.target.value)
  }

  const handleManufacturer = (e) => {
    setBulkManufacturer(!e.target.value || (e.target.value && e.target.value === 'none') ? '' : e.target.value)
  }

  const handleApproveRefresh = (e) => {
    getUsers();
  }

  const setChecked = (checkboxIndex) => {
    let arr = []
    requests.forEach((el, i) => {
      if(i === checkboxIndex) {
        el.isChecked = !el.isChecked
      }
      arr.push(el)
    });
    setRequests(arr)
  }

  const setAllChecked = () => {
    let arr = []
    requests.forEach((el, i) => {
      el.isChecked = !isAllChecked
      setIsAllChecked(!isAllChecked)
      arr.push(el)
    });
    setRequests(arr)
  }

  const setUserChecked = (checkboxIndex) => {
    let arr = []
    users.forEach((el, i) => {
      if(i === checkboxIndex) {
        el.isChecked = !el.isChecked
      }
      arr.push(el)
    });
    setUsers(arr)
  }

  const setAllUsersChecked = () => {
    let arr = []
    users.forEach((el, i) => {
      el.isChecked = !isAllUsersChecked
      setIsAllUsersChecked(!isAllUsersChecked)
      arr.push(el)
    });
    setUsers(arr)
  }

    const applyBulkActions = () => {
      let key = localStorage.getItem('key');
      let timestamp = localStorage.getItem('timestamp');
      if (!key || !timestamp) {
        localStorage.clear();
        history.push(`/login`);
      }
      let currentTime = Date.now();
      let timeLimit = parseInt(timestamp) + 12 * 60 * 60 * 1000;
        if (timeLimit <= currentTime) {
          localStorage.clear();
          history.push(`/login`);
        }

      let config = {
        headers:{
        "Authorization": `Bearer ${key}`
        }
      }

      let data
    if(bulkMode === 'approve') {

      data = {
        action: bulkMode,
        users: requests.filter(r => r.isChecked).map((r => r.id)),
        role: bulkRole
      }
    }

    if(bulkMode === 'reject') {
      data = {
        action: bulkMode,
        users: requests.filter(r => r.isChecked).map((r => r.id)),
        decline_reason: bulkReason
      }
    }

    if (!bulkMode) {
      setIsBulkPendingError("You must select an option");
      setTimeout(function () {
        setIsBulkPendingError(null);
      }, 3000);
      return;
    }
    if (bulkMode === 'approve' && !bulkRole) {
      setIsBulkPendingError("You must select a role");
      setTimeout(function () {
        setIsBulkPendingError(null);
      }, 3000);
      return;
    }
    if (!data.users.length) {
      setIsBulkPendingError("You must select at least one user");
      setTimeout(function () {
        setIsBulkPendingError(null);
      }, 3000);
      return;
    }


    setIsBulkRequestLoading(true)
      axios.post(process.env.REACT_APP_API_ENDPOINT + '/users/action', data, config)
        .then(res => {
          setIsBulkRequestLoading(false)
          console.log(res);
          getUsers();
        }).catch(err => {
          console.log(err.response)
        })
  }

  const applyBulkManufacturer = () => {
    setIsBulkError(null);
    let key = localStorage.getItem('key');
    let timestamp = localStorage.getItem('timestamp');
    if (!key || !timestamp) {
      localStorage.clear();
      history.push(`/login`);
    }
    let currentTime = Date.now();
    let timeLimit = parseInt(timestamp) + 12 * 60 * 60 * 1000;
      if (timeLimit <= currentTime) {
        localStorage.clear();
        history.push(`/login`);
      }

    let config = {
      headers:{
      "Authorization": `Bearer ${key}`
      }
    }
    let data = {
      manufacturers: [bulkManufacturer],
      users: users.filter(r => r.isChecked).map((r => r.id)),
    }
    if (!bulkManufacturer) {
      setIsBulkError("You must select a manufacturer");
      setTimeout(function () {
        setIsBulkError(null);
      }, 3000);
      return;
    }
    if (!data.users.length) {
      setIsBulkError("You must select at least one user");
      setTimeout(function () {
        setIsBulkError(null);
      }, 3000);
      return;
    }
    console.log(bulkManufacturer)
    console.log(data);

    axios.post(process.env.REACT_APP_API_ENDPOINT + '/users/manufacturer', data, config)
      .then(res => {
        setIsBulkSuccess("Updated");
        getUsers();
        setTimeout(function () {
          setIsBulkSuccess(null);
        }, 3000);
      }).catch(err => {
        console.log(err.response)
        setIsBulkError(err.response);
        setTimeout(function () {
          setIsBulkError(null);
        }, 3000);
      })
  }

  return(
      <UserManagementJsx
      users={users}
      requests={requests}
      isLoading={isLoading}
      manufacturerList={manufacturers}
      handleAction={handleAction}
      handleRole={handleRole}
      handleReason={handleReason}
      handleApproveRefresh={handleApproveRefresh}
      handleManufacturer={handleManufacturer}
      handleUserAction={handleUserAction}
      setChecked={setChecked}
      setAllChecked={setAllChecked}
      setUserChecked={setUserChecked}
      setAllUsersChecked={setAllUsersChecked}
      applyBulkActions={applyBulkActions}
      applyBulkManufacturer={applyBulkManufacturer}
      bulkMode={bulkMode}
      isBulkError={isBulkError}
      isBulkPendingError={isBulkPendingError}
      isBulkSuccess={isBulkSuccess}
      bulkUser={bulkUser}
      isBulkRequestLoading={isBulkRequestLoading}
      searchUser={searchUser}
      maxLimit={maxLimit}
      total={total}
      page={searchRequest.page}
      limit={limit}
      setActivePage={setActivePage}
      />
  )
}

export default UserManagement;
