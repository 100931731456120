import React from 'react';
import {Typography, Box, Button, CircularProgress,
        FormControlLabel, TextField, RadioGroup, Radio } from '@material-ui/core';

export const UserRejectJsx = (props) => {
return (
<div className='user-reject'>
<form onSubmit={(e) => props.handleSubmit(e)}>
<Box ml={9.97} mr={9.97} display="flex" justifyContent="center" flexDirection="column">
  <Box m={2.369} mt={3.157} mb={1.333}>
    <Typography align="center" variant="h5" className='blue-font'>
    <strong>Reject User Request</strong>
    </Typography>
  </Box>
  <Box my={1}>
    <Typography variant="subtitle2" className="support-text">
    <strong>User Role</strong>
    </Typography>
  </Box>
  <RadioGroup name="decline_reason" onChange={(e) => props.setFormValues(e)}>
    <FormControlLabel value='reason1' control={<Radio name="decline_reason" />} label="User no longer works for us" />
    <FormControlLabel value='reason2' control={<Radio name="decline_reason" />} label="Lorem ipsum dolor" />
    <FormControlLabel value='reason3' control={<Radio name="decline_reason" />} label="Lorem ipsum" />
    <FormControlLabel value='other' control={<Radio name="decline_reason" />} label="Other" />
  </RadioGroup>
  <TextField
  multiline
  rows={4}
  placeholder="Please specify..."
  variant="outlined"
  onKeyUp={(e) => {props.setFormValues(e)}}
/>
  <Box my={3.157} display={"flex"} justifyContent={"center"}>
  {!props.isLoading && !props.isSuccess &&
    <Button className='btn-theme' type='submit'>Reject Request</Button>
  }
  {props.isLoading && !props.isSuccess &&
    <CircularProgress/>
  }
  {props.isSuccess &&
    <Typography className='blue-font' align="center">User has been rejected</Typography>
  }
  </Box>
</Box>
</form>
</div>
)
}

export default UserRejectJsx;
