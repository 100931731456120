import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./Reports.scss";
import ReportsJsx from "./Reports.jsx";
import axios from "axios";

function Reports() {
  const [reports, setReports] = useState([]);
  const [reportTypes, setReportTypes] = useState([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [multipleStatement, setMultipleStatement] = useState("All");
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [downloadErr, setDownloadErr] = useState(null);
  const [isFirstRequest, setIsFirstRequest] = useState(true);
  const [isSecondRequest, setIsSecondRequest] = useState(true);
  const [searchRequest, setSearchRequest] = useState({
    page: 1,
    filter: {
      // xxx
    },
  });
  const maxLimit = 10;
  var searchTimeout = null;

  const history = useHistory();
  var filter = {};

  useEffect(() => {
    if(!isFirstRequest && !isSecondRequest) {
        getReports();
    }
    setIsFirstRequest(false);
    if(!isFirstRequest && isSecondRequest) {
    setIsSecondRequest(false);  
    }
  }, [searchRequest]);

  useEffect(() => {
    clearError();
  }, [downloadErr]);

  const triggerFilterQuery = (filterObj) => {
    console.log(filterObj);
    setSearchRequest({ filter: filterObj, page: 1 });
  };

  const clearError = () => {
    if (!downloadErr) {
      return;
    }
    setTimeout(() => {
      setDownloadErr(null);
    }, 3000);
  };

  const getQueryString = (downloadType) => {
    let obj = {},
      str = [];
    if (searchRequest.filter.type) {
      obj["type"] = searchRequest.filter.type;
    }
    if (searchRequest.filter.search) {
      obj["name"] = searchRequest.filter.search;
    }
    if (searchRequest.page) {
      obj["page"] = searchRequest.page;
    } else {
      obj["page"] = 1;
    }
    if (limit) {
      obj["limit"] = limit;
    } else {
      obj["limit"] = maxLimit;
    }
    if (searchRequest.filter.from) {
      obj["from"] = searchRequest.filter.from;
      obj["to"] = searchRequest.filter.to;
    }
    if (downloadType) {
      obj["download"] = 1;
    }
    console.log(obj);

    for (var p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    }
    return str.join("&");
  };

  const getReports = () => {
    let key = localStorage.getItem("key");
    let timestamp = localStorage.getItem("timestamp");
    if (!key || !timestamp) {
      localStorage.clear();
      history.push(`/login`);
    }
    let currentTime = Date.now();
    let timeLimit = parseInt(timestamp) + 12 * 60 * 60 * 1000;
    if (timeLimit <= currentTime) {
      localStorage.clear();
      history.push(`/login`);
    }

    let config = {
      headers: {
        Authorization: `Bearer ${key}`,
      },
    };
    let queryString = getQueryString();
    setIsLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_ENDPOINT}/reports?` + queryString,
        config
      )
      .then((res) => {
        console.log(res);
        setTotal(res.data.reports_total);
        setIsAllChecked(false);
        setMultipleStatement("All");
        console.log(res.data.report_types);
        if (res.data.report_types) {
          setReportTypes(res.data.report_types);
        }

        let d = res.data.reports_list;
        d.forEach((el, i) => {
          el.isChecked = false;
        });
        setReports(d);
        setIsLoading(false);
      });
  };

  const setActivePage = (page) => {
    setSearchRequest({ filter: searchRequest.filter, page: page });
  };

  const setAllChecked = () => {
    let arr = [];
    reports.forEach((el, i) => {
      el.isChecked = !isAllChecked;
      arr.push(el);
    });
    setIsAllChecked(!isAllChecked);
    let statement = getMultipleStatement();
    setMultipleStatement(statement);
    setReports(arr);
  };
  const getMultipleStatement = () => {
    var totalSelected = 0,
      allSelected = true;
    reports.forEach((el, i) => {
      if (!el.isChecked) {
        allSelected = false;
      } else {
        totalSelected += 1;
      }
    });
    if (!totalSelected) {
      return "All";
    }
    if (!allSelected) {
      return "Selected";
    }
    return "All Selected";
  };

  const setReportChecked = (checkboxIndex) => {
    let arr = [],
      areAllChecked = true;
    reports.forEach((el, i) => {
      if (i == checkboxIndex) {
        el.isChecked = !el.isChecked;
      }
      if (!el.isChecked) {
        areAllChecked = false;
      }
      arr.push(el);
    });
    setIsAllChecked(areAllChecked);
    let statement = getMultipleStatement();
    setMultipleStatement(statement);
    setReports(arr);
  };

  const downloadAll = () => {
    let key = localStorage.getItem("key");
    let timestamp = localStorage.getItem("timestamp");
    if (!key || !timestamp) {
      localStorage.clear();
      history.push(`/login`);
    }
    let currentTime = Date.now();
    let timeLimit = parseInt(timestamp) + 12 * 60 * 60 * 1000;
    if (timeLimit <= currentTime) {
      localStorage.clear();
      history.push(`/login`);
    }

    let queryString = getQueryString(true);

    let config = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${key}`,
        "Content-Type": "application/json",
      },
    };
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "Files.zip";
    setIsLoading(true);
    fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/reports?` + queryString,
      config
    )
      .then((response) => {
        if (response.status == 400) {
          setDownloadErr("Download failed");
          return;
        }
        return response.blob();
      })
      .then((response) => {
        link.href = URL.createObjectURL(
          new Blob([response], { type: response.type })
        );
        link.click();
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        return;
      });
  };

  const downloadSelected = () => {
    if (isLoading) {
      return;
    }
    if (multipleStatement == "All") {
      return downloadAll();
    }
    let reportsArr = [];
    reports.forEach((r, i) => {
      if (r.isChecked) {
        reportsArr.push(r.id);
      }
    });

    let key = localStorage.getItem("key");
    let timestamp = localStorage.getItem("timestamp");
    if (!key || !timestamp) {
      localStorage.clear();
      history.push(`/login`);
    }
    let currentTime = Date.now();
    let timeLimit = parseInt(timestamp) + 12 * 60 * 60 * 1000;
    if (timeLimit <= currentTime) {
      localStorage.clear();
      history.push(`/login`);
    }

    let data = {
      reports: reportsArr,
    };

    let config = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${key}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = "Files.zip";
    setIsLoading(true);
    fetch(
      process.env.REACT_APP_API_ENDPOINT + "/reports/download-multiple",
      config
    )
      .then((response) => {
        if (response.status == 400) {
          setDownloadErr("Download failed");
          return;
        }
        return response.blob();
      })
      .then((response) => {
        link.href = URL.createObjectURL(
          new Blob([response], { type: response.type })
        );
        link.click();
        setIsLoading(false);
      });
  };

  return (
    <ReportsJsx
      reports={reports}
      reportTypes={reportTypes}
      isLoading={isLoading}
      triggerFilterQuery={triggerFilterQuery}
      maxLimit={maxLimit}
      total={total}
      page={searchRequest.page}
      limit={limit}
      setActivePage={setActivePage}
      setAllChecked={setAllChecked}
      isAllChecked={isAllChecked}
      setReportChecked={setReportChecked}
      multipleStatement={multipleStatement}
      downloadSelected={downloadSelected}
      downloadErr={downloadErr}
    />
  );
}

export default Reports;
