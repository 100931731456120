import React, {useEffect} from 'react';
import {Modal,Typography, Grid, Box, Button, Checkbox} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

import UserApprove from '../modals/user-approve/UserApprove';
import UserReject from '../modals/user-reject/UserReject';

export const UserRequestJsx = (props) => {
  
  useEffect(() => {
      console.log('UserRequestJSX re-rendered')
  }, [props.requests])

return (
<div className='user-single'>
  <Grid container className="table-row">
    <Grid item xs={1}>
      <Checkbox onChange={() => props.setChecked(props.requestIndex)} checked={props.request.isChecked}/>
    </Grid>
    <Grid item xs={3}>
      <Box mt={1.16}>
      <Typography variant="subtitle2">
      {props.request.name}
      </Typography>
      </Box>
    </Grid>
    <Grid item xs={3}>
      <Box mt={1.16}>
      <Typography variant="subtitle2">
      {props.request.company_name}
      </Typography>
      </Box>
    </Grid>
    <Grid item xs={3}>
      <Box mt={1.16}>
      <Typography variant="subtitle2">
      {props.request.email}
      </Typography>
      </Box>
    </Grid>
    <Grid item xs={2}>
    <Box mt={1.16}>
    <Grid container>
      <Grid item xs={6} className="table-btn-container">
        <Button onClick={() => props.handleApproveOpen()} size="small" className="btn-theme" startIcon={< CheckIcon className='user-btn-icon'/>}>Accept</Button>
      </Grid>
      <Grid item xs={6} className="table-btn-container">
        <Button onClick={() => props.handleRejectOpen()} size="small" className="btn-theme-secondary" startIcon={< CloseIcon className='user-btn-icon' />}>Reject</Button>
      </Grid>
    </Grid>
    </Box>
    </Grid>
  </Grid>
  <Modal
  open={props.approveOpen}
  onClose={() => props.handleApproveClose()}
  >
  <UserApprove userID={props.request.id} manufacturerList={props.manufacturerList} handleApproveClose={() => props.handleApproveClose()} />
  </Modal>

  <Modal
  open={props.rejectOpen}
  onClose={() => props.handleRejectClose()}
  >
  <UserReject userID={props.request.id} />
  </Modal>
</div>
)
}

export default UserRequestJsx;
