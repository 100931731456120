import React from 'react';
import './Report.sass';
import {useHistory} from 'react-router-dom';
import ReportJsx from './Report.jsx';
import axios from "axios";

function Report(props) {
  const [previewModalOpen, setPreviewModalOpen] = React.useState(false);
  const [signedUrl, setSignedUrl] = React.useState('');
  const history = useHistory();

  const handleOpen = () => {
    let key = localStorage.getItem('key');
    let timestamp = localStorage.getItem('timestamp');
    if (!key || !timestamp) {
      localStorage.clear();
      history.push(`/login`);
    }
    let currentTime = Date.now();
    let timeLimit = parseInt(timestamp) + 12 * 60 * 60 * 1000;
    if (timeLimit <= currentTime) {
      localStorage.clear();
      history.push(`/login`);
    }

    // Get signed URL for report preview
    let config = {
      headers:{
        "Authorization": `Bearer ${key}`
      }
    }
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}${props.report.preview_url}`, config)
      .then(res => {
        console.log(res);
        if (res.data.signed_url) {
          setSignedUrl(res.data.signed_url);
          setPreviewModalOpen(true);
        }
      }).catch(err => {
        console.log(err);
      });
  }

  const handleClose = () => {
    setPreviewModalOpen(false);
    setSignedUrl('');
  }

  const handleDownload = () => {
    let key = localStorage.getItem('key');
    let timestamp = localStorage.getItem('timestamp');
    if (!key || !timestamp) {
      localStorage.clear();
      history.push(`/login`);
    }
    let currentTime = Date.now();
    let timeLimit = parseInt(timestamp) + 12 * 60 * 60 * 1000;
      if (timeLimit <= currentTime) {
        localStorage.clear();
        history.push(`/login`);
      }

    let config = {
      method: "GET",
      headers:{
      "Authorization": `Bearer ${key}`,
      }
    }
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = props.report.name

    fetch(`${process.env.REACT_APP_API_ENDPOINT}` + props.report.download_url, config).then(
    response => response.blob()
    ).then(response => {
    link.href = URL.createObjectURL(
      new Blob([response], { type: response.type })
    );
    link.click();
    })

  }

  return(
    <ReportJsx
      handleClose={handleClose}
      handleOpen={handleOpen}
      open={previewModalOpen}
      report={props.report}
      handleDownload={handleDownload}
      setReportChecked={props.setReportChecked}
      reportIndex={props.reportIndex}
      signedUrl={signedUrl}
    />
  )
}

export default Report;