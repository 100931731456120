import React, {useState, useEffect} from 'react';
import './ResetPassword.sass';
import ResetPasswordJsx from './ResetPassword.jsx';
import axios from 'axios';

function ResetPassword() {
  const [password, setPassword] = useState('');
  const [passwordTouched, setPasswordTouched] = useState('');
  const [passwordErr, setPasswordErr] = useState('');
  const [confirm, setConfirm] = useState('');
  const [confirmTouched, setConfirmTouched] = useState('');
  const [confirmErr, setConfirmErr] = useState('');
  const [isLoading, setIsLoading] = useState('');
  const [key, setKey] = useState('');
  const [confirmSuccess, setConfirmSuccess] = useState('');
  const [showForm, setShowForm] = useState(true);

  useEffect(() => {
    setKey(window.location.href.substring(window.location.href.lastIndexOf('/') + 1));
  }, [])

  useEffect(() => {
    if (!key) {
      return;
    }
    let data = {
      key: key
    }

    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/token/verify/reset`, data)
      .then(res => {
        console.log(res);
        setIsLoading(false);
        if(res.status !== 200) {
          setConfirmErr("Your reset token is not valid");
          setShowForm(false);
        }
      }).catch(err => {
        setShowForm(false);
        setIsLoading(false);
        setConfirmErr(err.response.data.error_message);
      })
  }, [key])

  const handleSubmit = (e) => {
    e.preventDefault();
    if((!confirm || confirm !== password) && confirmTouched) {
      setConfirmErr('Your passwords do not match')
      return;
    }
    let data = {
      password: password,
      key: key
    }
    setConfirmErr('')
    setPasswordErr('')
    setIsLoading(true);
    console.log(data)
    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/reset-password`, data)
      .then(res => {
        console.log(res);
        setIsLoading(false);
        if(res.status === 200) {
          setConfirmSuccess('Your password has been successfully reset. Please click the link below to login.');
        }
      }).catch(err => {
        setIsLoading(false);
        setConfirmErr(err.response.data.error_message);
      })
  }

  const setFormValues = (e) => {
    if(e.target.id === 'resetPassword') {
      setPasswordErr('');
      // setConfirmErr('');
      setPassword(e.target.value);
      setPasswordTouched(true);
    }
    if(e.target.id === 'confirmPassword') {
      setPasswordErr('');
      // setConfirmErr('');
      setConfirm(e.target.value);
      setConfirmTouched(true);
    }
  }

  useEffect(() => {
    validateForm();
  }, setFormValues)

  const validateForm = () => {
    setPasswordErr('')
    setConfirmErr('')
    if(!password.match(/^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$/) && passwordTouched) {
      setPasswordErr('Please enter a valid password (over 8 chars, using at least 1 uppercase, 1 lowercase and 1 special character)')
    }
    if((!confirm || confirm !== password) && confirmTouched) {
      setConfirmErr('Your passwords do not match')
    }
  }

  return(
    <ResetPasswordJsx
      handleSubmit={handleSubmit}
      setFormValues={setFormValues}
      isLoading={isLoading}
      passwordErr={passwordErr}
      confirmErr={confirmErr}
      confirmSuccess={confirmSuccess}
      showForm={showForm}
    />
  )
}

export default ResetPassword;
