import React, {useState, useEffect} from 'react';
import PaginatorJsx from './Paginator.jsx';

function Paginator(props) {
    const [currentPage, setCurrentPage] = useState(1);
    const [pages, setPages] = useState([]);

    useEffect(() => {
      setUpPages()
    },[])

    const setUpPages = () => {

        let total = props.total,
            currentPage = props.currentPage,
            limit = props.limit;

        if (!total) {
            setPages([]);
            return;
        }
        if (!currentPage || isNaN(parseInt(currentPage, 10)) || currentPage < 1) {
            currentPage = 1;
        }
        if (!limit || isNaN(parseInt(limit, 10)) || limit < 1 || limit > 100) {
            limit = 10;
        }
        let totalPages = Math.ceil(total / limit),
            pageList = [];
        for (let index=1; index <= totalPages; index += 1) {
            pageList.push(index);
        }
        if (currentPage > totalPages) {
            currentPage = 1;
        }
        setPages(pageList);
        setCurrentPage(currentPage);
    }

    const setActivePage = (page) => {
        props.setActivePage(page);
    }

    const moveActivePage = (direction) => {
        let page = currentPage;
        if (direction === 'next') {
            page += 1;
        } else {
            if (page > 1) {
                page -= 1;
            }
        }
        props.setActivePage(page);
    }

    return(
      <PaginatorJsx
        pages={pages}
        currentPage={currentPage}
        setActivePage={setActivePage}
        moveActivePage={moveActivePage}
      />
    )
}

export default Paginator;
