import { makeStyles} from '@material-ui/core/styles';

export const SidebarStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 240,
    background: '#EDF3F9',
    border: 'none'
  },
  icon: {
    minWidth: '30px'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    paddingTop: "20px"
  }
}))

export default SidebarStyles
