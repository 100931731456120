import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import axios from 'axios';
import './Sidebar.sass';
import SidebarJsx from './Sidebar.jsx';


function Sidebar() {
  const [supportModalOpen, setSupportModalOpen] = useState(false);
  const [role, setRole] = useState('')
  const history = useHistory();

  useEffect(() => {
    getUserRole()
  },[]) // eslint-disable-line react-hooks/exhaustive-deps

  const getUserRole = () => {
    let key = localStorage.getItem('key');
        let timestamp = localStorage.getItem('timestamp');
        if (!key || !timestamp) {
          localStorage.clear();
          history.push(`/login`);
        }
        let currentTime = Date.now();
        let timeLimit = parseInt(timestamp) + 12 * 60 * 60 * 1000;
          if (timeLimit <= currentTime) {
            localStorage.clear();
            history.push(`/login`);
          }

    let config = {
      headers:{
      "Authorization": `Bearer ${key}`
      }
    }
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/profile`, config)
      .then(res => {
        setRole(res.data.profile.role)
      }).catch(err => {
          if(err?.response.status) {
            history.push(`/login`);
          }
      })
  }


  const handleOpen = () => {
    setSupportModalOpen(true);
  }

  const handleClose = () => {
    setSupportModalOpen(false);
  }

  return(
      <SidebarJsx
      handleClose={handleClose}
      handleOpen={handleOpen}
      open={supportModalOpen}
      role={role}
      />
  )
}

export default Sidebar;
