import React from "react";
import Range from "./range/Range";
import Search from "./search/Search";
import { Grid, Button, InputLabel } from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

export const FiltersJsx = (props) => {
  return (
    <div className="full-width filters">
      <Range
        reportTypes={props.reportTypes}
        triggerRangeQuery={props.triggerRangeQuery}
        triggerTypeQuery={props.triggerTypeQuery}
      />
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <Button
            onClick={() => props.downloadSelected()}
            className="btn-theme"
            startIcon={<CloudDownloadIcon />}
            variant="contained"
            color="primary"
          >
            Download {props.multipleStatement}
          </Button>

          {
            <InputLabel className="error-label form-link error-label--download">
              {props.downloadErr}
            </InputLabel>
          }
        </Grid>
        <Grid item xs={6}>
          <Search triggerSearchQuery={props.triggerSearchQuery} />
        </Grid>
      </Grid>
    </div>
  );
};

export default FiltersJsx;
