import React from 'react';
import {Link} from 'react-router-dom';
import {Grid, Typography, Button, TextField, InputLabel, CircularProgress} from '@material-ui/core';

export const RegisterJsx = (props) => {
return (
<div className='register' id="register">
<Grid container spacing={0}>
  <Grid item xs={6} className='left-col'>
  </Grid>
  <Grid item xs={6} className='right-col'>
    <form className={`form-container ${props.fadedLeft ? 'faded-left' : 'not-faded'} ${props.fadedRight ? 'faded-right' : 'not-faded'}`} onSubmit={(e) => props.handleSubmit(e)}>
      <div className='logo-round'></div>
      <Typography align="center" variant="h5" className='register-header'>Register</Typography>
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <div className='form-group half-width'>
          <InputLabel className='form-label'>Full Name</InputLabel>
          <TextField size="small" id="formName" variant="outlined" type='text' className='full-width' onKeyUp={(e) => {props.setFormValues(e)}}/>
          <InputLabel className='error-label'>{props.nameErr}</InputLabel>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className='form-group half-width'>
          <InputLabel className='form-label'>Email</InputLabel>
          <TextField size="small" variant='outlined' id="formEmail" type='text' className='full-width' onKeyUp={(e) => {props.setFormValues(e)}} onBlur={(e) => {props.handleEmail(e)}}/>
          <InputLabel className='error-label'>{props.emailErr}</InputLabel>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className='form-group half-width'>
          <InputLabel className='form-label'>Company</InputLabel>
          <TextField size="small" variant='outlined' id="formCompany" type='text' className='full-width' onKeyUp={(e) => {props.setFormValues(e)}}/>
          <InputLabel className='error-label'>{props.companyErr}</InputLabel>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className='form-group half-width'>
          <InputLabel className='form-label'>Website</InputLabel>
          <TextField size="small" variant='outlined' id="formWebsite" type='text' className='full-width' onKeyUp={(e) => {props.setFormValues(e)}}/>
          <InputLabel className='error-label'>{props.websiteErr}</InputLabel>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className='form-group half-width'>
          <InputLabel className='form-label'>Password</InputLabel>
          <TextField size="small" variant='outlined' id="formPassword" type='password' className='full-width' onKeyUp={(e) => {props.setFormValues(e)}}/>
          <InputLabel className='error-label'>{props.passwordErr}</InputLabel>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div className='form-group half-width'>
          <InputLabel className='form-label'>Re-Enter Password</InputLabel>
          <TextField size="small" variant='outlined' id="formPasswordConfirm" type='password' className='full-width' onKeyUp={(e) => {props.setFormValues(e)}}/>
          <InputLabel className='error-label'>{props.passwordConfirmErr}</InputLabel>
          </div>
        </Grid>
        <Grid container justify="center">
          {
          !props.isLoading && <Button variant="contained" align="center" className='cms-btn-primary' type='submit'>Register</Button>
          }
          {
          props.isLoading && <CircularProgress className="centered" />
          }
          <Link className='form-link turquoise-font' exact to="/login">Already have an account?</Link>
          {<InputLabel className='error-label form-link'>{props.registrationErr}</InputLabel>}
        </Grid>
        </Grid>
      </form>
  </Grid>
</Grid>
</div>
)
}

export default RegisterJsx;
