import {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 20,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  pagination: {
    '& .MuiPaginationItem-root': {
      padding: 14,
      margin: 0,
      height: 'unset',
      borderRadius: 5,
      fontSize: '1em',
      fontWeight: 700,
      '&.Mui-selected': {
        background: '#28B3AC',
        color: '#fff',
      },
    },
  },
  toggleBtn: {
    fontSize: '1em',
    fontFamily: 'sans-serif',
    display: 'flex',
    alignItems: 'center',
    padding: 14,
    borderRadius: 5,
    fontWeight: 700,
    cursor: 'pointer',
  },
}));

export const PaginatorJsx = (props) => {
  const [page, setPage] = useState();
  const classes = useStyles();

  useEffect(() => setPage(props.currentPage), [props.currentPage]);

  const handleChange = (e, value) => {
    setPage(value);
    props.setActivePage(value);
  };

  return (
    <>
      {props.pages.length > 1 &&
        <div className={classes.root}>
          {page > 1 &&
            <span className={classes.toggleBtn} onClick={(e) => {props.moveActivePage('prev')}}>Prev</span>
          }
          <Pagination
            className={classes.pagination}
            count={props.pages.length}
            page={page}
            onChange={handleChange}
            // Hide default paginator prev and next buttons to enable adding custom buttons
            hidePrevButton
            hideNextButton
            siblingCount={3}
          />
          {page < props.pages.length &&
            <span className={classes.toggleBtn} onClick={(e) => {props.moveActivePage('next')}}>Next</span>
          }
        </div>
      }
    </>
  )
}

export default PaginatorJsx;
