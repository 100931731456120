import React from 'react';
import './Preview.sass';
import PreviewJsx from './Preview.jsx';

function Preview(props) {
  return(
    <div>
      <PreviewJsx
        signedUrl={props.signedUrl}
        reportName={props.reportName}
      />
    </div>
  )
}

export default Preview;
