import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import './Login.sass';
import LoginJsx from './Login.jsx';

import axios from 'axios';

function Login() {

  const [username, setUsername] = useState('');
  const [usernameTouched, setUsernameTouched] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [authErr, setAuthErr] = useState('');
  const [loginErr, setLoginErr] = useState('');
  const [fadedLeft, setFadedLeft] = useState(true)
  const [fadedRight, setFadedRight] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setFadedLeft(true);
    setTimeout(function () {
    setFadedLeft(false)
  }, 100);
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!username) {
      setLoginErr("Email is required");
      return;
    }
    if (!password) {
      setLoginErr("Password is required");
      return;
    }
    let data = {
      username: username,
      password: password
    }
    setIsLoading(true);
    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/login`, data)
      .then(res => {
        console.log(res);
        setIsLoading(false);
        if(res.status === 200) {
          setFadedRight(true);
          setTimeout(function () {
            history.push(`/verify/${res.data.key}?login`);
          }, 500);
        }
      }).catch(err => {
        setIsLoading(false);
        // console.log(err.response.data.error_message)
        setLoginErr(err?.response?.data?.error_message)
      })
  }

  const setFormValues = (e) => {
    if (e.target.id === 'loginEmail') {
      setUsername(e.target.value);
      setUsernameTouched(true);
    }
    if (e.target.id === 'loginPassword') {
      setPassword(e.target.value);
      setPasswordTouched(true);
    }
  }

  useEffect(() => {
    checkAuth()
  }, [])

  const checkAuth = () => {
    let key = localStorage.getItem('key');
    let timestamp = localStorage.getItem('timestamp');
    let currentTime = Date.now();
    let timeLimit = parseInt(timestamp) + 12 * 60 * 60
    if (timestamp && key && key != "undefined" && timeLimit < currentTime) {
        history.push(`/dashboard`);
      }
    }

  return(
    <div>
      <LoginJsx
      handleSubmit={handleSubmit}
      setFormValues={setFormValues}
      isLoading={isLoading}
      loginErr={loginErr}
      fadedLeft={fadedLeft}
      fadedRight={fadedRight}/>
    </div>
  )
}

export default Login;
