import React from "react";
import {
  Grid,
  Box,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
} from "@material-ui/core";

export const RangeJsx = (props) => {
  return (
    <div className="range">
      <Box my={2.369}>
        <Grid container spacing={0}>
          <Grid item xs={3}>
            <Box mb={0.75}>
              <InputLabel className="blue-font">Report Type</InputLabel>
            </Box>
              <Select
              id="reportType"
              className="input-theme"
              size="small"
              onChange={(e) => props.triggerTypeQuery(e)}
              defaultValue={"none"}
            >
              {props.reportTypes &&
                props.reportTypes.map((reportType, i) => {
                  return (
                    <MenuItem key={i} value={reportType.value}>
                      {reportType.name}
                    </MenuItem>
                  )
                })}
            </Select>
          </Grid>
          <Grid item xs={3}>
            <Box mb={0.75}>
              <InputLabel className="blue-font">Year</InputLabel>
            </Box>
            <Select
              className="input-theme"
              id="reportYear"
              onChange={(e) => props.triggerYearQuery(e)}
              defaultValue={props.currentYear}
            >
              <MenuItem value={"none"}>Please select</MenuItem>
              {props.yearList.map((year) => {
                return (
                  <MenuItem value={year}>{year}</MenuItem>
                )
              })}

            </Select>
          </Grid>
          <Grid item xs={3}>
            <Box mb={0.75}>
              <InputLabel className="blue-font">Month</InputLabel>
            </Box>
            <Select
              className="input-theme"
              id="reportMonth"
              onChange={(e) => props.triggerMonthQuery(e)}
              defaultValue="none"
            >
              <MenuItem value={"none"}>Please select</MenuItem>
              <MenuItem value={"01"}>January</MenuItem>
              <MenuItem value={"02"}>February</MenuItem>
              <MenuItem value={"03"}>March</MenuItem>
              <MenuItem value={"04"}>April</MenuItem>
              <MenuItem value={"05"}>May</MenuItem>
              <MenuItem value={"06"}>June</MenuItem>
              <MenuItem value={"07"}>July</MenuItem>
              <MenuItem value={"08"}>August</MenuItem>
              <MenuItem value={"09"}>September</MenuItem>
              <MenuItem value={"10"}>October</MenuItem>
              <MenuItem value={"11"}>November</MenuItem>
              <MenuItem value={"12"}>December</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={3}>
            <Box mb={0.75}>
              <InputLabel className="blue-font">Week</InputLabel>
            </Box>
            <Select
              className="input-theme"
              id="reportDay"
              onChange={(e) => props.triggerWeekQuery(e)}
              defaultValue="none"
            >
              <MenuItem value={"none"}>Please select</MenuItem>
              <MenuItem value={"this"}>This Week</MenuItem>
              <MenuItem value={"last"}>Last Week</MenuItem>
              <MenuItem value={"twoWeeks"}>2 Weeks Ago</MenuItem>
            </Select>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default RangeJsx;
