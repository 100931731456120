import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./Profile.sass";
import ProfileJsx from "./Profile.jsx";
import axios from "axios";

function Profile() {
  const [isLoading, setIsLoading] = useState("");
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordTouched, setPasswordTouched] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [confirm, setConfirm] = useState("");
  const [confirmTouched, setConfirmTouched] = useState("");
  const [confirmErr, setConfirmErr] = useState("");
  const [confirmSuccess, setConfirmSuccess] = useState("");
  const [supportModalOpen, setSupportModalOpen] = useState(false);
  const history = useHistory();

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = () => {
    let key = localStorage.getItem("key");
    let timestamp = localStorage.getItem("timestamp");
    if (!key || !timestamp) {
      localStorage.clear();
      history.push(`/login`);
    }
    let currentTime = Date.now();
    let timeLimit = parseInt(timestamp) + 12 * 60 * 60 * 1000;
    if (timeLimit <= currentTime) {
      localStorage.clear();
      history.push(`/login`);
    }

    let config = {
      headers: {
        Authorization: `Bearer ${key}`,
      },
    };
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/profile`, config)
      .then((res) => {
        console.log(res);
        setName(res.data.profile.name);
        setEmail(res.data.profile.email);
        setCompany(res.data.profile.company_name);
      })
      .catch((err) => {
        console.log(err.response.status);
        if (err.response.status) {
          history.push(`/login`);
        }
      });
  };

  const handleOpen = () => {
    setSupportModalOpen(true);
  };

  const handleClose = () => {
    setSupportModalOpen(false);
  };

  const setFormValues = (e) => {
    if (e.target.id === "resetPassword") {
      setPasswordErr("");
      // setConfirmErr('');
      setPassword(e.target.value);
      setPasswordTouched(true);
    }
    if (e.target.id === "confirmPassword") {
      setPasswordErr("");
      // setConfirmErr('');
      setConfirm(e.target.value);
      setConfirmTouched(true);
    }
  };

  useEffect(() => {
    validateForm();
  }, setFormValues);

  const validateForm = () => {
    setPasswordErr("");
    setConfirmErr("");
    if (
      !password.match(/^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$/) &&
      passwordTouched
    ) {
      setPasswordErr(
        "Please enter a valid password (over 8 chars, using at least 1 uppercase, 1 lowercase and 1 special character)"
      );
    }
    if ((!confirm || confirm !== password) && confirmTouched) {
      setConfirmErr("Your passwords do not match");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if ((!confirm || confirm !== password) && confirmTouched) {
      setConfirmErr("Your passwords do not match");
      return;
    }
    setConfirmErr("");
    setPasswordErr("");
    setIsLoading(true);

    let data = {
      password: password,
      confirm_password: confirm,
    };

    let key = localStorage.getItem("key");

    let config = {
      headers: {
        Authorization: `Bearer ${key}`,
      },
    };

    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT}/change-password`,
        data,
        config
      )
      .then((res) => {
        setIsLoading(false);
        if (res.status === 200) {
          setConfirmSuccess("Your password has been successfully reset.");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setConfirmErr(err.response.data.error_message);
      });
  };

  return (
    <ProfileJsx
      handleClose={handleClose}
      handleOpen={handleOpen}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      open={supportModalOpen}
      name={name}
      email={email}
      company={company}
      setFormValues={setFormValues}
      confirmSuccess={confirmSuccess}
      confirmErr={confirmErr}
    />
  );
}

export default Profile;
