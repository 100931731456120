import React from 'react';
import {Typography, Grid, Box, Button, TextField, Select, InputLabel, MenuItem, CircularProgress} from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

export const UserEditJsx = (props) => {
console.log(props.userManufacturers);
return (
<div className='main-container user-edit'>
<Box mx={4.209}>
  <Box mt={2.369}>
  <Typography variant="h4" className="support-title">
  User
  </Typography>
  </Box>
  {props.user && 
    <form onSubmit={(e) => props.handleSubmit(e)}>
    <Grid container>
          <Grid item xs={6}>
              <Box my={1.777}>
                <div className='profile-info-group'>
                <Typography variant="subtitle1" className="bold blue-font">
                Full Name
                </Typography>
                <TextField id="userName" variant="outlined" defaultValue={props.user.name} className="input-theme" onKeyUp={(e) => props.setFormValues(e)}></TextField>
                </div>
              </Box>
          </Grid>
          <Grid item xs={6}>
            <Box my={1.777}>
              <div className='profile-info-group'>
              <Typography  variant="subtitle1" className="bold blue-font">
              Email
              </Typography>
              <TextField id="userEmail" variant="outlined" className="input-theme" defaultValue={props.user.email} onKeyUp={(e) => props.setFormValues(e)}></TextField>
              </div>
            </Box>
          </Grid>
    <Grid item xs={6}>
        <Box my={1.777}>
          <div className='profile-info-group'>
          <Typography  variant="subtitle1" className="bold blue-font">
            Company
          </Typography>
          <TextField id="userCompany" variant="outlined" defaultValue={props.user.company} disabled className="input-theme" onKeyUp={(e) => props.setFormValues(e)}></TextField>
          </div>
        </Box>
    </Grid>
    <Grid item xs={6}>
      <Box my={1.777}>
        <div className='profile-info-group'>
        <Typography  variant="subtitle1" className="bold blue-font">
        Website
        </Typography>
        <TextField id="userWebsite" variant="outlined" className="input-theme" defaultValue={props.user.website} disabled onKeyUp={(e) => props.setFormValues(e)}></TextField>
        </div>
      </Box>
    </Grid>
    </Grid>
    <Grid container>
      <Grid item xs={6} className="user-edit-single-col">
      <Typography  variant="subtitle2">
      <strong>User Role</strong>
      </Typography>
      <Box mb={1.777}>
      <Select id="userRole" defaultValue={props.user.role} variant="outlined" className="select-input" onChange={(e) => props.setDropdownValues(e)}>
        <MenuItem value="vendor_user">Vendor User</MenuItem>
        <MenuItem value="vendor_admin">Vendor Admin</MenuItem>
      </Select>
      </Box>
      <Typography  variant="subtitle2">
      <strong>Manufacturers</strong>
      </Typography>
      {!props.isLoading && props.userManufacturers.length > 0 && props.userManufacturers.map((user_manufacturer, i) => {
        return <div key={i} className="manufacturer-select-container">
            <Select variant={"outlined"} value={user_manufacturer ? user_manufacturer : " "}className="select-input" onChange={(e) => props.updateManufacturer(e, i)}>
              {props.manufacturers.map((manufacturer, i) => {
                return (
                  <MenuItem value={manufacturer.id}>{manufacturer.name}</MenuItem>
                  )
              })}
            </Select>
            {props.role === 'vendor_user' && <DeleteOutlinedIcon onClick={() => props.removeManufacturer(i)} className='delete-icon'/>}
            {props.role === 'vendor_admin' && <DeleteOutlinedIcon className='delete-icon delete-icon--disabled'/>}
            </div>
      })}

      <Box mb={3.157}>
        <Button onClick={() => props.addManufacturer()} startIcon={<AddCircleIcon />} className='btn-add-manufacturer'>Add Manufacturer</Button>
      </Box>
      </Grid>
    </Grid>
    {<InputLabel className='error-label form-link'>{props.errorMessage}</InputLabel>}
    {!props.isLoading && !props.isSuccess && <Grid container>
      <Box mr={1}>
        <Button className='btn-theme' type="submit">Apply Changes</Button>
      </Box>
      <Box ml={1}>
        <Button className='btn-theme-secondary' href={'/dashboard/user-management'}>Discard Changes</Button>
      </Box>
    </Grid>
    }
    {props.isLoading && !props.isSuccess && <Box display="flex" justifyContent="center"><CircularProgress /></Box>}
    {props.isSuccess && 
      <Grid container>
      <Box display="flex" justifyContent="center"><Typography variant="h6" className="blue-font"><CheckCircleIcon /><strong> User Updated!</strong></Typography></Box>
      <Box ml={1}>
        <Button className='btn-theme-secondary' href={'/dashboard/user-management'}>Back</Button>
      </Box>
      </Grid>
    }
    </form>
  }
</Box>
</div>
)
}

export default UserEditJsx;
