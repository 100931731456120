import React from 'react';
import {TextField, FormControl} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

export const SearchJsx = (props) => {
  return (
    <div className='search'>
      <FormControl size="small" className='input-theme'>
        <TextField
          size="small"
          variant="outlined"
          className='input-theme'
          placeholder='Search for a report...'
          type='text'
          onKeyUp={(e) => props.triggerSearchQuery(e)}
        />
        <div className="icon-container">
          <SearchIcon className='icon'/>
        </div>
      </FormControl>
    </div>
  )
}

export default SearchJsx;
