import React, {useState, useEffect, useRef} from 'react';
import './Register.sass';
import RegisterJsx from './Register.jsx';
import {useHistory} from 'react-router-dom';
import axios from 'axios';

function Register() {
  // FORM VALUES
  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [website, setWebsite] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  // ERROR MESSAGES
  const [nameErr, setNameErr] = useState('');
  const [companyErr, setCompanyErr] = useState('');
  const [emailErr, setEmailErr] = useState('');
  const [websiteErr, setWebsiteErr] = useState('');
  const [passwordErr, setPasswordErr] = useState('');
  const [passwordConfirmErr, setPasswordConfirmErr] = useState('');
  // INPUT TOUCHED
  const [nameTouched, setNameTouched] = useState(false);
  const [companyTouched, setCompanyTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [websiteTouched, setWebsiteTouched] = useState(false);
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [passwordConfirmTouched, setPasswordConfirmTouched] = useState(false);
  // LOADING
  const [isLoading, setIsLoading] = useState(false);
  const [fadedLeft, setFadedLeft] = useState(true);
  const [fadedRight, setFadedRight] = useState(false);
  const [registrationErr, setRegistrationErr] = useState('');
  const history = useHistory();

  const setFormValues = (e) => {
    if(e.target.id === 'formName') {
      setName(e.target.value);
      setNameTouched(true);
    }
    if(e.target.id === 'formCompany') {
      setCompany(e.target.value);
      setCompanyTouched(true);
    }
    if(e.target.id === 'formEmail'){
      setEmail(e.target.value)
      setEmailTouched(true)
    }
    if(e.target.id === 'formWebsite'){
      setWebsite(e.target.value)
      setWebsiteTouched(true)
    }
    if(e.target.id === 'formPassword'){
      setPassword(e.target.value)
      setPasswordTouched(true)
    }
    if(e.target.id === 'formPasswordConfirm'){
      setPasswordConfirm(e.target.value)
      setPasswordConfirmTouched(true)
    }
  }

  useEffect(() => {
    setFadedLeft(true);
    setTimeout(function () {
    setFadedLeft(false)
  }, 100);
  }, [])

  useEffect(() => {
    validateForm();

  }, [name, company, website, password, passwordConfirm, email]);

  const validateForm = () => {
    setNameErr('')
    setCompanyErr('')
    setEmailErr('')
    setWebsiteErr('')
    setPasswordErr('')
    setPasswordConfirmErr('')


    if(!name && nameTouched) {
      setNameErr('Name is required')
    }
    if(!company && companyTouched) {
      setCompanyErr('This field is required')
    }
    if(!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) && emailTouched) {
      setEmailErr('Please enter a valid email')
    }
    if(!website.match(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/) && websiteTouched) {
      setWebsiteErr('Please enter a valid website URL')
    }
    if(!password.match(/^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*[~@#$%^&*+=`|{}:;!._,?"'/()\\<>[\]-]).*$/) && passwordTouched) {
      setPasswordErr('Please enter a valid password (over 8 chars, using at least 1 uppercase, 1 lowercase and 1 special character)')
    }
    if((!passwordConfirm || passwordConfirm !== password) && passwordConfirmTouched) {
      setPasswordConfirmErr('Your passwords do not match')
    }
  }

  const handleEmail = (e) => {
    setIsLoading(true);
    if(!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) && emailTouched) {
      setEmailErr('Please enter a valid email')
      return;
    }
    let data = {
      email: email
    }
    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/confirm-email`, data)
      .then(res => {
        setIsLoading(false);
        setEmailErr("");
      }).catch(err => {
        setIsLoading(false);
        console.log(err.response.data.error_message)
        if (err.response.data.error_reason) {
          if (err.response.data.error_reason == 'spam') {
            setEmailErr("That email address is flagged as spam");
          } else if (err.response.data.error_reason == 'free') {
            setEmailErr("Please ensure you use a company domain.");
          } else {
            setEmailErr(err.response.data.error_message + ". Reason: " + err.response.data.error_reason);
          }
        } else {
          setEmailErr(err.response.data.error_message);
        }
      })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
      if (!name) {
        setNameErr("This field is required")
      }
      if (!email) {
        setEmailErr("This field is required")
      }
      if (!company) {
        setCompanyErr("This field is required")
      }
      if (!website) {
        setWebsiteErr("This field is required")
      }
      if (!password) {
        setPasswordErr("This field is required")
      }
      if (!passwordConfirm) {
        setPasswordConfirmErr("This field is required")
      }
      if (!name || !email || !company || !website || !password || !passwordConfirm) {
        return;
      }
      if (password != passwordConfirm) {
        setPasswordConfirmErr("Passwords do not match")
        return;
      }
            let data = {
              name: name,
              email: email,
              company: company,
              website: website,
              password: password,
              confirm_password: passwordConfirm
            }
            console.log(data);
            axios.post(`${process.env.REACT_APP_API_ENDPOINT}/registration-request`, data)
              .then(res => {
                setIsLoading(false);
                if(res.status === 200) {
                  setFadedRight(true)
                  setTimeout(function () {
                    history.push(`/verify/${res.data.key}?register`);
                  }, 500);
                }
              }).catch(err => {
                setIsLoading(false);
                setRegistrationErr(err.response.data.error_message)
              })

  }

  return(
    <div>
      <RegisterJsx
        setFormValues={setFormValues}
        handleSubmit={handleSubmit}
        handleEmail={handleEmail}
        nameErr={nameErr}
        companyErr={companyErr}
        emailErr={emailErr}
        websiteErr={websiteErr}
        passwordErr={passwordErr}
        passwordConfirmErr={passwordConfirmErr}
        isLoading={isLoading}
        registrationErr={registrationErr}
        fadedLeft={fadedLeft}
        fadedRight={fadedRight}
      />
    </div>
  )
}

export default Register;
