import React from 'react';
import FileViewer from "react-file-viewer";

export const PreviewJsx = (props) => {
  return (
    <div className='preview'>
      {
        !props.signedUrl &&
        <h2 className='error-text'>Unable to generate file preview</h2>
      }
      {
          props.signedUrl &&
          <>
            <h2 className='modal-text'>{props.reportName}</h2>
            <FileViewer
              fileType={'xlsx'}
              filePath={props.signedUrl}
              onError={() => console.log('error loading preview')}
            />
          </>
      }
    </div>
  )
}

export default PreviewJsx;
