import React from 'react';
import {AppBar, Toolbar, Typography, IconButton, Link} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';


export const HeaderJsx = (props) => {
return (
  <AppBar position="static" className='app-header'>
    <Toolbar variant="dense">
      <IconButton edge="start"
      color="inherit" aria-label="menu">
        <MenuIcon />
      </IconButton>
      <Typography variant="h6" className="title">
        CMS Distribution
      </Typography>
      <Link href="/dashboard/profile"><div className='header-icon user-icon'></div></Link>
      <Link href="/dashboard/profile"><span role='button' className='nav-item' >Profile</span></Link>
      <div className='header-icon padlock-icon' onClick={() => props.logout()}></div>
    <span role='button' className='nav-item' onClick={() => props.logout()}>Log out</span>
    </Toolbar>
  </AppBar>
)
}

export default HeaderJsx;
