import React, {useState, useEffect} from 'react';
import './UserRequest.sass';
import UserRequestJsx from './UserRequest.jsx';

function UserRequest(props) {
  const [approveModalOpen, setApproveModalOpen] = useState(false);
  const [rejectModalOpen, setRejectModalOpen] = useState(false);

  useEffect(() => {
      console.log('UserRequestJS re-rendered')
  }, [props.requests])

  const handleApproveOpen = () => {
    setApproveModalOpen(true);
  }

  const handleApproveClose = () => {
    setApproveModalOpen(false);
    props.handleApproveRefresh()
  }

  const handleRejectOpen = () => {
    setRejectModalOpen(true);
  }

  const handleRejectClose = () => {
    setRejectModalOpen(false);
    props.handleApproveRefresh()
  }

  return(
      <UserRequestJsx
      approveOpen={approveModalOpen}
      handleApproveOpen={handleApproveOpen}
      handleApproveClose={handleApproveClose}
      rejectOpen={rejectModalOpen}
      handleRejectOpen={handleRejectOpen}
      handleRejectClose={handleRejectClose}
      request={props.request}
      manufacturerList={props.manufacturerList}
      setChecked={props.setChecked}
      requestIndex={props.requestIndex}
      />
  )
}

export default UserRequest;
