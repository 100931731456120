import React from 'react';
import {useHistory} from 'react-router-dom';
import './Header.sass';
import HeaderJsx from './Header.jsx';

function Header() {
  const history = useHistory();
  const logout = () => {
    localStorage.removeItem('key');
    history.push('/')
  }

  return(
      <HeaderJsx
      logout={logout}
      />
  )
}

export default Header;
