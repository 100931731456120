import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./Verify.sass";
import VerifyJsx from "./Verify.jsx";
import axios from "axios";

function Verify() {
  const [key, setKey] = useState("");
  const [token, setToken] = useState("");
  const [authMode, setAuthMode] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const [autoSubmit, setAutoSubmit] = useState(false);
  const [verificationError, setVerificationError] = useState("");
  const [verificationRefresh, setVerificationRefresh] = useState("");
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [fadedLeft, setFadedLeft] = useState(true);
  const [fadedRight, setFadedRight] = useState(false);
  const history = useHistory();

  useEffect(() => {
    console.log(window.location.pathname.match(/-/g).length);
    if (window.location.pathname.match(/-/g).length == 5) {
      setKey(
        window.location.pathname.substring(
          window.location.pathname.lastIndexOf("/") + 1,
          window.location.pathname.lastIndexOf("-")
        )
      );
      console.log(window.location.pathname);
      console.log(window.location.href);
      console.log(
        window.location.href.substring(
          window.location.href.lastIndexOf("-") + 1,
          window.location.href.lastIndexOf("?")
        )
      );
      let tempToken = window.location.href.substring(
        window.location.href.lastIndexOf("-") + 1,
        window.location.href.lastIndexOf("?")
      );
      console.log(tempToken);
      setToken(tempToken);
      setAutoSubmit(true);
    } else {
      setKey(
        window.location.pathname.substring(
          window.location.pathname.lastIndexOf("/") + 1,
          window.location.pathname.length
        )
      );
    }

    setAuthMode(
      window.location.href.substring(window.location.href.lastIndexOf("?") + 1)
    );
    setFadedLeft(true);
    setTimeout(function () {
      setFadedLeft(false);
    }, 100);
  }, []);

  useEffect(() => {
    console.log(key);
    console.log(token);
  }, [key, token]);

  useEffect(() => {
    handleSubmit();
  }, [autoSubmit]);

  const setFormValues = (e) => {
    if (e.target.id === "verificationCode") {
      setToken(e.target.value);
    }
  };

  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (!token) {
      setVerificationError("You must complete the form before continuing");
      return;
    }
    let data = {
      key: key,
      token: token,
    };
    console.log(data);
    setIsLoading(true);
    if (authMode == "login") {
      axios
        .post(`${process.env.REACT_APP_API_ENDPOINT}/token/verify/2fa`, data)
        .then((res) => {
          console.log(res);
          let timestamp = Date.now();
          localStorage.setItem("key", res.data.token);
          localStorage.setItem("timestamp", timestamp);
          setIsLoading(false);
          setFadedRight(true);
          setTimeout(function () {
            history.push(`/dashboard`);
          }, 500);
        })
        .catch((err) => {
          setVerificationError(err.response.data.error_message);
          setIsLoading(false);
        });
    } else if (authMode == "register") {
      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/token/verify/registration`,
          data
        )
        .then((res) => {
          setIsLoading(false);
          setRegistrationSuccess(true);
          localStorage.setItem("key", res.data.token);
        })
        .catch((err) => {
          setIsLoading(false);
          setVerificationError(err.response.data.error_message);
        });
    }
  };

  const resendVerification = () => {
    let data = {
      key: key,
    };
    if (authMode == "login") {
      axios
        .post(`${process.env.REACT_APP_API_ENDPOINT}/token/refresh/2fa`, data)
        .then((res) => {
          setVerificationRefresh(
            "A new verification code has been sent to your inbox"
          );
        })
        .catch((err) => {
          setVerificationError(err.response.data.error_message);
        });
    } else if (authMode == "register") {
      axios
        .post(
          `${process.env.REACT_APP_API_ENDPOINT}/token/refresh/registration`,
          data
        )
        .then((res) => {
          setVerificationRefresh(
            "A new verification code has been sent to your inbox"
          );
        })
        .catch((err) => {
          setVerificationError(err.response.data.error_message);
        });
    }
  };
  return (
    <div>
      <VerifyJsx
        handleSubmit={handleSubmit}
        setFormValues={setFormValues}
        isLoading={isLoading}
        verificationError={verificationError}
        verificationRefresh={verificationRefresh}
        resendVerification={resendVerification}
        registrationSuccess={registrationSuccess}
        fadedLeft={fadedLeft}
        fadedRight={fadedRight}
      />
    </div>
  );
}
export default Verify;
