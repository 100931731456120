import React from "react";
import Preview from "./preview/Preview";
import {
  Typography,
  Grid,
  Box,
  Button,
  Checkbox,
  Modal,
} from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import SearchIcon from "@material-ui/icons/Search";

export const ReportJsx = (props) => {
  return (
    <Grid
      container
      spacing={0}
      className={`report ${props.report.isChecked && "checked"}`}
    >
      <Grid item xs={1}>
        <Box mt={1}>
          <Checkbox
            onChange={() => props.setReportChecked(props.reportIndex)}
            checked={props.report.isChecked}
            value={1}
            defaultChecked={false}
          />
        </Box>
      </Grid>
      <Grid item xs={3} className="report-col report-name">
        <Typography variant="subtitle2" className="reports-title">
          {props.report.name}
        </Typography>
      </Grid>
      <Grid item xs={2} className="report-col">
        <Typography variant="subtitle2">{props.report.report_type}</Typography>
      </Grid>
      <Grid item xs={2} className="report-col">
        <Typography variant="subtitle2">{props.report.created_date}</Typography>
      </Grid>
      <Grid item xs={4} className="report-col">
        <Button
          className="btn-theme"
          size="small"
          startIcon={<CloudDownloadIcon />}
          onClick={() => props.handleDownload()}
        >
          Download
        </Button>
      </Grid>

      <Modal
        className="preview-modal"
        open={props.open}
        onClose={() => props.handleClose()}
      >
        <Preview signedUrl={props.signedUrl} reportName={props.report.name} />
      </Modal>
    </Grid>
  );
};

export default ReportJsx;
