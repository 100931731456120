import React, {useState} from 'react';
import './Search.sass';
import SearchJsx from './Search.jsx';

function Search(props) {

  var searchTimeout = null;
  const [currentSearch, setCurrentSearch] = useState([]);

  const triggerSearchQuery = (e) => {
    var searchItem = e.target.value;
    clearTimeout(searchTimeout);
    if (searchItem != currentSearch) {
      setCurrentSearch(searchItem);
      searchTimeout = setTimeout(() => { console.log("Querying!"); props.triggerSearchQuery(searchItem); }, 350);
    }
  }

  return(
    <div>
      <SearchJsx
      triggerSearchQuery={triggerSearchQuery}
       />
    </div>
  )
}

export default Search;
