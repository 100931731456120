import React, {useState, useEffect} from 'react';
import './UserSingle.sass';
import UserSingleJsx from './UserSingle.jsx';

function UserSingle(props) {
    const [showAllChecked, setShowAllChecked] = useState(false)

  useEffect(() => {
    setShowAllChecked(false)
  }, [])

  const handleViewAllClick = (e) => {
    setShowAllChecked(true)
  }

  return(
      <UserSingleJsx
      user={props.user}
      userIndex={props.userIndex}
      setUserChecked={props.setUserChecked}
      handleViewAllClick={handleViewAllClick}
      showAllChecked={showAllChecked}
      />
  )
}

export default UserSingle;
