import React from 'react';
import {Typography, Box, Button, Checkbox,
        FormGroup, FormControlLabel, Select, MenuItem, InputLabel } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

export const UserApproveJsx = (props) => {

return (
<div className='user-approve'>
<form onSubmit={(e) => props.handleSubmit(e)}>
<Box ml={9.97} mr={9.97} display="flex" justifyContent="center" flexDirection="column">
  <Box m={2.369} mt={3.157} mb={1.333}>
    <Typography align="center" variant="h5" className='blue-font'>
    <strong>Accept User Request</strong>
    </Typography>
  </Box>
  <Box my={1}>
    <Typography variant="subtitle2" className="support-text">
    <strong>User Role</strong>
    </Typography>
  </Box>
  <FormGroup>
    <FormControlLabel onClick={() => props.toggleManufacturerAdmin()} control={<Checkbox checked={!props.isManufacturerAdmin ? true : false} name="vendor" />} label="User" />
    <FormControlLabel onClick={() => props.toggleManufacturerAdmin()} control={<Checkbox checked={props.isManufacturerAdmin ? true : false} value="vendor_admin" name="admin" />} label="Admin" />
  </FormGroup>
  <Box mt={1}>
    <Typography variant="subtitle2" className="support-text">
    <strong>Manufacturers</strong>
    </Typography>
  </Box>
  <Box mt={1}>
    {props.manufacturers.map((manufacturer, i) => {
      return <div key={i} className="manufacturer-select-container">
    <Select variant={"outlined"} className={"select-input"}  onChange={(e) => props.handleChange(e, i)}>
    { props.manufacturerList.map((v, j) => {
      return <MenuItem key={j} value={v.id}>{v.name}</MenuItem>
    })
    }
    </Select>
    {i === props.manufacturers.length - 1 && <DeleteOutlinedIcon onClick={() => props.removeManufacturer(i)} className='delete-icon'/>}
    </div>
    })}
  </Box>
  <Box mb={3.157}>
    <Button onClick={() => props.addManufacturer()}startIcon={<AddCircleIcon />} className='btn-add-manufacturer'>Add Manufacturer</Button>
  </Box>
  <Box mb={3.157}>
    <Button className='btn-theme' type="submit">Accept Request</Button>
  </Box>
  {<InputLabel className='error-label form-link'>{props.isError}</InputLabel>}
</Box>
</form>
</div>
)
}

export default UserApproveJsx;
